import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import brandMark from "../assets/svg/brandmark.svg";
import useFetch from "../hooks/useFetch";
import TCPDF from "../assets/Membership_Agreement_TCs_GROUNDUP_Happana_(1.4).pdf";
import SFPDF from "../assets/Schedule_of_Fees_(Ground Up)_(1.2).pdf";
import GPPDF from "../assets/GROUNDUP_Policies.pdf";
import { useLocation } from "@reach/router";
import GU_Viva from "../assets/svg/vivaleisure_GU.svg";
import GU_WhiteLogo from "../assets/svg/GU-whitelogo.svg";
import Wellness_Viva from "../assets/svg/vivaleisure_Wellness.svg";

import Facebook_Mobile from "../assets/images/wellness/facebookWellness.svg";
import Facebook_Amber from "../assets/images/wellness/facebook_amber.svg";
import Instagram_Mobile from "../assets/images/wellness/instagramWellness.svg";
import Instagram_Amber from "../assets/images/wellness/instagram_amber.svg";
import Tiktok_Mobile from "../assets/images/wellness/tiktokWellness.svg";
import Tiktok_Amber from "../assets/images/wellness/tiktok_amber.svg";

import apple_mobile from "../assets/images/wellness/apple.svg";
import apple_amber from "../assets/images/wellness/apple_amber.svg";
import google_mobile from "../assets/images/wellness/google.svg";
import google_amber from "../assets/images/wellness/google_amber.svg";
import wellness_Green_Viva from "../assets/svg/vivaleisure_Wellness_Green.svg";
import viva_amber from "../assets/svg/vivaleisure_GU.svg";
import GU_LOGO_STONE from "../assets/svg/GU_Logo-Icon_Lockup_RGB_11-2024_STONE.svg";
import GU_LOGO_AMBER from "../assets/svg/GU_Logo-Icon_Lockup_RGB_11-2024_AMBER.svg";

function Footer(props: any) {
  const { page } = props;
  return (
    <div
      className={`${
        page === "wellness" ? "bg-[#274240]" : "bg-[#E1C4B2]"
      } w-full pt-[2.5rem] md:pt-[4.813rem] px-[1.75rem] md:px-[12.125rem] pb-[1.313rem]  mobile-footer`}
    >
      <>
        <div className="hidden md:block">
          <div className="max-w-[1440px] mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="flex flex-col h-full justify-between">
              {page === "wellness" ? (
                <Link to="/">
                  <img
                    src={GU_LOGO_STONE}
                    alt="GroundUp Logo"
                    className="w-[140px] h-auto mb-4"
                  />
                </Link>
              ) : (
                <Link to="/">
                  <img
                    src={GU_LOGO_AMBER}
                    alt="GroundUp Logo"
                    className="w-[140px] h-auto mb-4"
                  />
                </Link>
              )}
              <div>
                <p
                  className={`${
                    page === "wellness" ? "text-white" : "amber-text"
                  }  mb-0`}
                >
                  A division of
                </p>
                {page === "wellness" ? (
                  <img
                    src={Wellness_Viva}
                    alt="Viva Leisure"
                    className="w-[120px] h-auto mt-4"
                  />
                ) : (
                  <img
                    src={GU_Viva}
                    alt="Viva Leisure"
                    className="w-[120px] h-auto mt-4"
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <ul className="list-none">
                <li className="mb-2">
                  <Link
                    to="/about"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    About
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/studios"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Studios
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/classes"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Classes
                  </Link>
                </li>
                <li className="mb-2">
                  <Link
                    to="/timetable"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Timetable
                  </Link>
                </li>
                {/* <li className="mb-2">
                  <Link
                    to="/pricing"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Pricing
                  </Link>
                </li> */}
                {/* <li className="mb-2">
                  <Link
                    to="/manage-membership"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Manage Membership
                  </Link>
                </li> */}
                {page !== "wellness" && (
                  <li className="mb-2">
                    <Link
                      to="/suspend-membership"
                      className={`${
                        page === "wellness" ? "text-[#ffffff]" : "amber-text"
                      } capitalize text-[1rem]`}
                    >
                      Suspend membership
                    </Link>
                  </li>
                )}
                {page !== "wellness" && (
                  <li className="mb-2">
                    <Link
                      to="/cancel-membership"
                      className={`${
                        page === "wellness" ? "text-[#ffffff]" : "amber-text"
                      } capitalize text-[1rem]`}
                    >
                      Cancel membership
                    </Link>
                  </li>
                )}
                {/* <li className="mb-2">
                  <Link
                    to="/book"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem]`}
                  >
                    Book
                  </Link>
                </li>
                <li className="mb-[2.2rem]">
                  <Link
                    to="/faqs"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } capitalize text-[1rem] `}
                  >
                    FAQs
                  </Link>
                </li> */}
                <div className="mt-[30px] ">
                  <Link
                    to={page === "wellness" ? "/" : "/wellness"}
                    className={`${
                      page === "wellness"
                        ? "bg-[#693927] border border-white text-[#ffffff]"
                        : "bg-[#274240] text-[#CADED5] "
                    } px-4 py-2 font-[400]  text-[1rem] text-white rounded-full `}
                  >
                    {page === "wellness" ? "Movement" : "Wellness"}
                  </Link>
                </div>
                <div className="mt-[68px] ">
                  <p
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } mb-[12px]`}
                  >
                    Download the GROUNDUP App
                  </p>
                  <div className="flex space-x-4 gap-4">
                    <a
                      href="https://apps.apple.com/au/app/groundup/id6479574546?utm_source=email&utm_medium=email+marketing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-[6px]"
                    >
                      {page === "wellness" ? (
                        <img src={apple_mobile} alt="App Store" />
                      ) : (
                        <img src={apple_amber} alt="App Store" />
                      )}
                      <p
                        className={`${
                          page === `wellness`
                            ? `text-[#CADED5]`
                            : `text-[#693927]`
                        } m-0 text-[14px] font-[400]`}
                      >
                        App Store
                      </p>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.hapana.groundupapp&utm_source=email&utm_medium=email+marketing"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-[6px]"
                    >
                      {page === "wellness" ? (
                        <img src={google_mobile} alt="Google Play" />
                      ) : (
                        <img src={google_amber} alt="Google Play" />
                      )}
                      <p
                        className={`${
                          page === `wellness`
                            ? `text-[#CADED5]`
                            : `text-[#693927]`
                        } m-0 text-[14px] font-[400]`}
                      >
                        Google Play
                      </p>
                    </a>
                  </div>
                </div>
              </ul>
            </div>
            <div className="flex flex-col">
              <ul className="list-none">
                <li className="mb-2">
                  <a
                    href="https://www.clublime.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } captialize text-[1rem]`}
                  >
                    Club Lime
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    href="https://www.hiitrepublic.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } captialize text-[1rem]`}
                  >
                    HIIT Republic
                  </a>
                </li>
                {/* <li className="mb-2">
                  <Link
                    to="/work-with-us"
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } captialize text-[1rem]`}
                  >
                    Work with us
                  </Link>
                </li> */}
                <div className="mb-[2.5rem]">
                  <p
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    }  text-[14px] font-[400] mb-[9px] `}
                  >
                    info@groundup.studio
                  </p>
                  <p
                    className={`${
                      page === "wellness" ? "text-[#ffffff]" : "amber-text"
                    } text-[14px] font-[400] mb-[9px]`}
                  >
                    131244
                  </p>
                  <div className="flex space-x-4 items-center mb-[9px]">
                    {page === "wellness" ? (
                      <a
                        href="https://www.facebook.com/profile.php?id=61568281096611"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Facebook_Mobile} alt="Facebook" />
                      </a>
                    ) : (
                      <a
                        href="https://www.facebook.com/groundup.studio.au"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Facebook_Amber} alt="Facebook" />
                      </a>
                    )}
                    <i className="fab fa-facebook-square text-2xl">
                      {page === "wellness" ? (
                        <a
                          href="https://www.instagram.com/groundup.wellness/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Instagram_Mobile} alt="Instagram" />
                        </a>
                      ) : (
                        <a
                          href="https://www.instagram.com/groundup.studio"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Instagram_Amber} alt="Instagram" />
                        </a>
                      )}
                    </i>
                    <i className="fab fa-tiktok text-2xl">
                      {page === "wellness" ? (
                        <a
                          href="https://www.tiktok.com/@groundup.wellness"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Tiktok_Mobile} alt="Tiktok" />
                        </a>
                      ) : (
                        <a
                          href="https://www.tiktok.com/@groundup.studio"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Tiktok_Amber} alt="Tiktok" />
                        </a>
                      )}
                    </i>
                  </div>
                </div>
              </ul>
            </div>
            <div className="flex flex-col">
              <h3
                className={`${
                  page === "wellness" ? "text-[#ffffff]" : "amber-text"
                }  font-[400] mb-[1.938rem] uppercase text-[1.75rem] whitespace-nowrap`}
              >
                Join the {page === "wellness" ? "Journey" : "Movement"}
              </h3>
              <p
                className={`${
                  page === "wellness" ? "text-[#ffffff]" : "amber-text"
                } text-[0.875rem]  mb-[1.938rem]`}
              >
                Reformer, Pilates, Yoga, and Barre. Strengthen your body and
                refocus your mind in our personalised, effective classes. Join
                the movement at GROUNDUP.
              </p>
              <Link
                to={`${
                  page === "wellness" ? "/wellness#download" : "/join-now"
                }`}
                className={`${
                  page === "wellness"
                    ? "bg-[#CADED5] text-[#274240] hover:bg-transparent hover:text-[#CADED5] border border-transparent hover:border-[#CADED5] px-4 py-2 rounded"
                    : "bg-[#5C3D2E] text-[#ffffff]"
                } px-4 py-2 font-[1rem] rounded-full font-[400] uppercase w-fit`}
              >
                Join Now
              </Link>
            </div>
          </div>
          <div
            className={`${
              page === "wellness"
                ? "text-[#ffffff] border-[#CADED5]"
                : "amber-text border-[#5C3D2E]"
            } border-t items-center max-w-[1440px] mx-auto mt-[4.188rem] pt-[1.375rem] flex justify-between text-[0.75rem] `}
          >
            <p className="m-0">&copy; COPYRIGHT 2024 GROUNDUP</p>
            <div className="flex space-x-4">
              {/* <Link
                to="/investors"
                className={`${
                  page === "wellness" ? "text-[#ffffff]" : "amber-text"
                } hover:underline uppercase`}
              >
                Investors
              </Link> */}
              <a
                href={GPPDF}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  page === "wellness" ? "text-[#ffffff]" : "amber-text"
                } hover:underline uppercase`}
              >
                Privacy Policy
              </a>
              <a
                href={TCPDF}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  page === "wellness" ? "text-[#ffffff]" : "amber-text"
                } hover:underline uppercase`}
              >
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
        <div
          className={`${
            page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
          } md:hidden `}
        >
          <div className="grid grid-cols-2 gap-x-[3rem] gap-y-[1.875rem] mb-[1.875rem]">
            <div className="flex flex-col gap-[1.875rem]">
              {/* <button className="text-[#ffffff] text-[1rem] font-[400] py-[10px] px-[22px] rounded-full bg-[#693927] uppercase hover:bg-[#E1C4B2] hover:text-[#693927] transition duration-150">
                Movements
              </button> */}
              <Link
                to="/"
                className="bg-[#693927] border border-white text-[#ffffff] px-4 py-2 font-[400] uppercase text-[1rem] text-white rounded-full text-center"
              >
                movement
              </Link>
            </div>
            <div className="flex flex-col gap-[1.875rem]">
              {/* <h2
                className={` ${
                  isWellness ? "text-[#CADED5]" : "text-[#693927]"
                } text-[1.5rem] font-[400] py-[10px] pr-[22px] rounded-full`}
              >
                Wellness
              </h2> */}
              <Link
                to="/wellness"
                className="bg-[#274240] text-[#CADED5] px-4 py-2 font-[400] uppercase text-[1rem] text-white rounded-full text-center"
              >
                wellness
              </Link>
              <div className="space-y-2 text-1"></div>
            </div>
          </div>

          {/* <div className="flex justify-between mb-8">
            
            
          </div> */}

          <div className="mb-[3.25rem]">
            <p className="mb-[12px]">Download the GROUNDUP App</p>
            <div className="flex space-x-4 gap-4">
              <a
                href="https://apps.apple.com/au/app/groundup/id6479574546?utm_source=email&utm_medium=email+marketing"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-[6px]"
              >
                {page === "wellness" ? (
                  <img src={apple_mobile} alt="App Store" />
                ) : (
                  <img src={apple_amber} alt="App Store" />
                )}
                <p
                  className={`${
                    page === `wellness` ? `text-[#CADED5]` : `text-[#693927]`
                  } m-0 text-[14px] font-[400]`}
                >
                  App Store
                </p>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.hapana.groundupapp&utm_source=email&utm_medium=email+marketing"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-[6px]"
              >
                {page === "wellness" ? (
                  <img src={google_mobile} alt="Google Play" />
                ) : (
                  <img src={google_amber} alt="Google Play" />
                )}
                <p
                  className={`${
                    page === `wellness` ? `text-[#CADED5]` : `text-[#693927]`
                  } m-0 text-[14px] font-[400]`}
                >
                  Google Play
                </p>
              </a>
            </div>
          </div>

          <div className="flex gap-[55px] justify-items mb-[2rem] md:mb-[2.625rem] text-[14px] font-[400]">
            <div>
              <Link
                to="/join-now"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Join Now</p>
              </Link>
              <Link
                to="/about"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>About</p>
              </Link>
              <Link
                to="/timetable"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Timetable</p>
              </Link>
              <Link
                to="/studios"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Studios</p>
              </Link>
              <Link
                to="/classes"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Classes</p>
              </Link>
            </div>
            <div>
              <Link
                to="/suspend-membership"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Suspend Membership</p>
              </Link>
              <Link
                to="/cancel-membership"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Cancel Membership</p>
              </Link>
              {/* <Link
                to="/investors"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Investors</p>
              </Link> */}
              <a
                href={GPPDF}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Privacy Policy</p>
              </a>
              <a
                href={TCPDF}
                target="_blank"
                rel="noopener noreferrer"
                className={`${
                  page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
                }`}
              >
                <p>Terms and Conditions</p>
              </a>
            </div>
          </div>

          <div className="mb-[2.5rem]">
            <a
              className={`${
                page === "wellness" ? "text-[#CADED5]" : "text-[#693927]"
              } "text-[14px] font-[400] mb-[9px]`}
              href="mailto:info@groundup.studio"
            >
              info@groundup.studio
            </a>
            <p className="text-[14px] font-[400] mb-[9px]">131244</p>
            <div className="flex space-x-4 mb-[9px]">
              <i className="fab fa-facebook-square text-2xl">
                {page === "wellness" ? (
                  <a
                    href="https://www.facebook.com/profile.php?id=61568281096611"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Facebook_Mobile} alt="Facebook" />
                  </a>
                ) : (
                  <a
                    href="https://www.facebook.com/groundup.studio.au"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Facebook_Amber} alt="Facebook" />
                  </a>
                )}
              </i>

              <i className="fab fa-facebook-square text-2xl">
                {page === "wellness" ? (
                  <a
                    href="https://www.instagram.com/groundup.wellness/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Instagram_Mobile} alt="Instagram" />
                  </a>
                ) : (
                  <a
                    href="https://www.instagram.com/groundup.studio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Instagram_Amber} alt="Instagram" />
                  </a>
                )}
              </i>

              <i className="fab fa-tiktok text-2xl">
                {page === "wellness" ? (
                  <a
                    href=" https://www.tiktok.com/@groundup.wellness"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Tiktok_Mobile} alt="Tiktok" />
                  </a>
                ) : (
                  <a
                    href="https://www.tiktok.com/@groundup.studio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Tiktok_Amber} alt="Tiktok" />
                  </a>
                )}
              </i>
            </div>
          </div>

          <div
            className={` ${
              page === "wellness" ? "border-[#CADED5]" : "border-[#693927]"
            } flex flex-col gap-[16px] border-t  border-[#CADED5] pt-[1.5rem] justify-start text-left`}
          >
            <p
              className={`${
                page === "wellness" ? "text-[#CADED5]" : "amber-text"
              } text-[14px]  font-[400] m-0`}
            >
              A division of
            </p>
            <a href="https://vivaleisure.group">
              <img
                src={page === "wellness" ? wellness_Green_Viva : viva_amber}
                alt="Viva Leisure Logo"
                className="mr-auto "
              />
            </a>
            <p
              className={` ${
                page === "wellness" ? "text-[#CADED5]" : "amber-text"
              } text-[12px] font-[400] m-0`}
            >
              © COPYRIGHT 2024 GROUNDUP
            </p>
          </div>
        </div>
      </>
    </div>
  );
}

export default Footer;

{
  /* Mobile Footer (new code) */
}
