import React from "react";
import Challenge from "../assets/images/movement/Challenge.jpg";
import ChallengeMobile from "../assets/images/movement/ChallengeMobile.jpg";
import Header from "../assets/images/movement/Header.png";
import HeaderMobile from "../assets/images/movement/HeaderMobile.jpg";
import Prizes from "../assets/images/movement/Prizes.png";
import PrizesMobile from "../assets/images/movement/PrizesMobile.jpg";
import Welcome from "../assets/images/movement/Welcome.png";
import WelcomeMobile from "../assets/images/movement/WelcomeMobile.jpg";
import JoinNowMobile from "../assets/images/movement/JoinNowMobile.jpg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import apple_amber from "../assets/images/wellness/apple_amber.svg";
import google_amber from "../assets/images/wellness/google_amber.svg";

function Movement() {
  return (
    <div>
      <Navbar />
      <img
        src={Header}
        className="w-full sm:block hidden h-full object-contain"
        alt="banner"
      />
      <div className="relative w-full h-full">
        <img
          src={HeaderMobile}
          className="w-full sm:hidden block  object-contain"
          alt="banner"
        />
        {/* <img
          src={Text}
          className="absolute left-1/2 sm:hidden block   top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          alt=""
        /> */}
      </div>
      <img
        src={Welcome}
        className="w-full sm:block hidden h-full object-contain"
        alt="welcome"
      />
      <img
        src={WelcomeMobile}
        className="w-full sm:hidden block h-full object-cover"
        alt="welcome"
      />
      <div className="relative">
        <img
          src={Challenge}
          className="hidden sm:block  w-full  h-full object-contain"
          alt="Join"
        />
        <img
          src={ChallengeMobile}
          className="block sm:hidden  w-full  h-full object-contain"
          alt="Join"
        />
        <div className="flex absolute top-[55%] sm:top-[22%]  left-1/2 -translate-x-1/2 flex flex-col sm:flex-row items-center gap-4 sm:gap-6 text-[#693927] ">
          <a
            href="https://apps.apple.com/au/app/groundup/id6479574546"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-[#E0CAC4] text-[#693927] hover:text-[#693927] active:text-[#693927] visited:text-[#693927] rounded-full font-semibold sm:font-medium uppercase text-base sm:text-sm md:text-base whitespace-nowrap"
          >
            <div className="flex items-center gap-2 ">
              <span>
                <img src={apple_amber} className="mb-1" alt="App Store" />
              </span>{" "}
              App Store
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.hapana.groundupapp&hl=en_AU"
            target="_blank"
            rel="noopener noreferrer"
            className="px-4 py-2 bg-[#E0CAC4] text-[#693927] hover:text-[#693927] active:text-[#693927] visited:text-[#693927] rounded-full font-semibold sm:font-medium uppercase text-base sm:text-sm md:text-base whitespace-nowrap"
          >
            <div className="flex items-center gap-2">
              <span>
                <img src={google_amber} className="mb-1" alt="App Store" />
              </span>{" "}
              Play Store
            </div>
          </a>
        </div>
      </div>
      <img
        src={JoinNowMobile}
        className="w-full sm:hidden block h-full object-contain"
        alt="Join"
      />
      <img
        src={Prizes}
        className="w-full h-full sm:block hidden object-contain"
        alt="Prizes"
      />
      <img
        src={PrizesMobile}
        className="w-full h-full sm:hidden block object-contain"
        alt=""
      />
      <Footer page="movement" />

      {/* <div className="relative">
        <div className="sm:block hidden w-full">
          <img
            src={Download}
            className="w-full h-full object-cover"
            alt="Download"
          />
          <div className="absolute flex flex-col gap-4 right-[15%] top-1/2 transform -translate-y-1/2">
            <div className="flex items-start gap-7">
              <img src={Gu} alt="" />
              <img src={TextDl} alt="" />
            </div>
            <div className="flex items-center gap-7">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/groundup-studio/id1572605761?itsct=apps_box_badge&itscg=30200"
              >
                <img src={Ios} alt="" />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.vivaleisure.groundupMain&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img src={Gg} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="w-full object-contain sm:hidden block  relative">
          <img src={Link} className="w-full object-contain" alt="" />
          <div className=" absolute justify-center bottom-5 w-full left-1/2 transform -translate-x-1/2 flex items-center gap-7">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/groundup-studio/id1572605761?itsct=apps_box_badge&itscg=30200"
            >
              <img className="min-w-[40%]" src={Ios} alt="" />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.vivaleisure.groundupMain&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            >
              <img className="min-w-[40%]" src={Gg} alt="" />
            </a>
          </div>
        </div>
      </div>
      <img
        src={Footer}
        className="w-full cursor-pointer h-full object-contain"
        alt="footer"
      /> */}
    </div>
  );
}

export default Movement;
